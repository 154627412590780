import { easings } from '../utils/variables';

$(document).ready(() => {
	const $window = $(window);
	const $body = $('body');
	const $loader = $('.mc-loader');
	const $siteWrapper = $('.mc-site-wrapper');
	const $logo = $('.mc-header__logo');
	let windowHasLoaded = false;

	const loaderTL = new TimelineMax({
		paused: true,
		onComplete: () => {
			$window.trigger('page-loaded');
		}
	});

	loaderTL
		.to($loader, .3, {delay: .5, opacity: 0, autoAlpha: 0, ease: easings.default})
		.from($logo, .3, {x: -10, opacity: 0, ease: easings.default});

	function onWindowLoad() {
		windowHasLoaded = true;
		loaderTL.play(0);
	}

	$window.on('load', function () {
		onWindowLoad();
	});

	setTimeout(function () {
		if(!windowHasLoaded) {
			onWindowLoad();
		}
	}, 5000);
});