$(document).ready(() => {
	const $window = $(window);
	const $body = $('body');
	const lockedScrollClass = 'locked-scroll';

	let scrollPosition;

	const lockScroll = () => {
		$body.css('overflow', 'hidden');

		if(Modernizr.touch) {
			scrollPosition = $window.scrollTop();
			$body.addClass(lockedScrollClass);
			TweenMax.set($body, {top: -scrollPosition});
		}
	}

	const unlockScroll = () => {
		$body.css('overflow', '');

		if(Modernizr.touch) {
			$body.removeClass(lockedScrollClass);
			TweenMax.set($body, {top: ''});

			window.scrollTo(0, scrollPosition);
		}
	}

	$window
		.on('lock-scroll', lockScroll)
		.on('unlock-scroll', unlockScroll);
});