import { keyCodes, easings, zIndex } from '../utils/variables';

$(document).ready(() => {
  const $document = $(document);
	const $window = $(window);
	const $openModal = $('[data-open-modal-type]');
	const $closeModal = $('[data-close-modal]');
	const modalClass = '.mc-modal';
	const $modals = $(modalClass);
	const $modalOverlay = $('.mc-modal-overlay');
	const $modalPassword = $modals.filter('[data-modal-type="password"]');
	const $modalPasswordLink = $('#modalPasswordLink');

	const openModal = m => {
		const $modal = $(m);

		if(!$modal.attr('data-modal-open')) {
			const tl = new TimelineMax({
				onStart: () => {
					$modal.show();
					$modalOverlay.show();
					$modal.attr('data-modal-open', true);
					$window
						.trigger('disable-cursor')
						.trigger('lock-scroll');
				}
			});

			tl
				.set($modal, {zIndex: zIndex.modal})
				.add('t')
				.fromTo($modal, .15, {opacity: 0, autoAlpha: 0}, {opacity: 1, autoAlpha: 1, ease: easings.default}, 't')
				.fromTo($modal, .4, {skewX: -5, xPercent: -4}, {skewX: 0, xPercent: 0, ease: easings.bounce}, 't');
		}
	}

	const closeModal = m => {
		const $modal = $(m);

		if($modal.attr('data-modal-open')) {
			const tl = new TimelineMax({
				onComplete: () => {
					TweenMax.set($modal, {clearProps: 'all'});
					$modal.attr('data-modal-open', null);
					$modal.hide();
					$modalOverlay.hide();
					$modalPasswordLink.attr('href', null);

					$window
						.trigger('enable-cursor')
						.trigger('unlock-scroll');
				}
			});

			tl
				.add('t')
				.fromTo($modal, .125, {opacity: 1, autoAlpha: 1, skewX: 0}, {opacity: 0, autoAlpha: 0, xPercent: 2, skewX: -1, ease: easings.default}, 't');
		}
	}

	const openModalPassword = target => {
		$modalPasswordLink.attr('href', target);
		openModal($modalPassword);
	}

	const closeModalPassword = () => {
		closeModal($modalPassword);
	}

	const handleOpenModal = (i, el) => {
		const $btn = $(el);
		const modalType = $btn.data('open-modal-type');

		const handleClick = e => {
			e.preventDefault();

			switch(modalType) {
				case 'password':
					let target = $btn.attr('link-target');
					target && openModalPassword(target);
					break;
				default:
					return false;
					break;
			}
		}

		$btn.on('click', handleClick);
	}

	const handleCloseModal = (i, el) => {
		const $btn = $(el);
		const $modalParent = $btn.closest($modals);
		let modalType = $modalParent.length && $modalParent.data('modal-type');

		const handleClick = e => {
			$btn.attr('href') === '#' && e.preventDefault();

			if(!$modalParent.length) {
				modalType = $('[data-modal-open]').data('modal-type');
			}

			switch(modalType) {
				case 'password':
					closeModalPassword();
					break;
				default:
					return false;
					break;
			}
		}

		$btn.on('click', handleClick);
	}

	const triggerCloseModal = () => $modalOverlay.trigger('click');

	const onDocumentKeyUp = (e) => {
		const $activeModal = $('[data-modal-open="true"]');

		($activeModal.length && e.keyCode == keyCodes.esc) && triggerCloseModal();
	}


	$openModal.length && $openModal.each(handleOpenModal);
	$closeModal.length && $closeModal.each(handleCloseModal);
	$document.on('keyup', onDocumentKeyUp);
});