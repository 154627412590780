import { easings, colors } from '../utils/variables';

$(document).ready(() => {
	const ctaClass = '.mc-cta';
	const $ctas = $(ctaClass);

	const handleCta = (i, el) => {
		const $cta = $(el);
		const $icon = $cta.find(ctaClass + '__icon');

		let on = false;

		const toggleState = () => {
			on = !on;
		}

		const hoverTL = new TimelineMax({
			paused: true,
			onComplete: toggleState,
			onReverseComplete: toggleState
		});

		hoverTL
			.add('t')
			.from($icon, .25, {delay: .1, opacity: 0, x: -10, skewX: -50, ease: easings.default}, 't');

		const onMouseenter = () => {
			hoverTL.play();
		}

		const onMouseleave = () => {
			hoverTL.reverse();
		}

		$cta
			.mouseenter(onMouseenter)
			.mouseleave(onMouseleave);
	}

	$ctas.length && $ctas.each(handleCta);
});