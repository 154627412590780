import { easings } from '../utils/variables';

$(document).ready(() => {
	const headingLinkClass = '.mc-heading__link';
	const $headingLinks = $(headingLinkClass);

	const handleHeadingLink = (i, el) => {
		const $link = $(el);
		const $infoContent = $link.find(headingLinkClass + '--info').find('.content');

		let on = false;

		const toggleState = () => {
			on = !on;
		}

		const hoverTL = new TimelineMax({
			paused: true,
			onComplete: toggleState,
			onReverseComplete: toggleState
		});

		hoverTL
			.add('t')
			.from($infoContent, .2, {delay: .25, opacity: 0, x: -10, skewX: -30, ease: easings.default}, 't');

		const onMouseenter = () => {
			hoverTL.timeScale(1).play(0);
		}

		const onMouseleave = () => {
			hoverTL.timeScale(2).reverse();
		}

		if(!Modernizr.touch) {
			$link
				.mouseenter(onMouseenter)
				.mouseleave(onMouseleave);
		}
	}

	$headingLinks.length && $headingLinks.each(handleHeadingLink);
})