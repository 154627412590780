import { colors, easings } from '../utils/variables';

$(document).ready(() => {
  if(!Modernizr.touch) {
    const $window = $(window);
    const $cursor = $('#customCursor');
    const $cursorCircle = $('.mc-cursor__circle');
    const cursorSizeSmall = 20;
    const cursorSizeLarge = 130;
    const $links = $('a');
    const $modals = $('.mc-modal');
    const cursorClasses = {
      disabled: 'mc-cursor--disabled',
      modal: 'mc-cursor--modal',
    }

    let init;
    let timer = false;
    let on = false;

    TweenMax.set($cursor, {opacity: 0, scale: 0});

    const cursor = {
      width: $cursor.width(),
      height: $cursor.height(),
    }

    const positionCursor = e => {
      let yValue = e.clientY - cursor.height / 2 + 'px';
      let xValue = e.clientX - cursor.width / 2 + 'px';

      if(!on) {
        TweenMax.set($cursor, {top: yValue, left: xValue});
        TweenMax.to($cursor, .2, {opacity: 1, scale: 1});
        on = true;
      } else {
        TweenMax.to($cursor, .15, {top: yValue, left: xValue, ease: easings.default});
      }

      if($modals.is(e.target) || $modals.has(e.target).length) {
        $cursor.addClass(cursorClasses.modal);
      } else {
        $cursor.removeClass(cursorClasses.modal);
      }
    }

    $window.on('mousemove', e => positionCursor(e));

    const resetCursor = () => {
      TweenMax.set($cursorCircle, {clearProps: 'all'});
    }

    const cursorTL = new TimelineMax({
      paused: true,
      onReverseComplete: resetCursor,
    });

    cursorTL
      .to($cursorCircle, .25, {scale: 10, backgroundColor: '#001485', ease: easings.default});

    $links
      .mouseenter(() => cursorTL.play())
      .mouseleave(() => cursorTL.reverse());

    const disableCursor = () => $cursor.addClass(cursorClasses.disabled);
    const enableCursor = () => $cursor.removeClass(cursorClasses.disabled);

    $window
      .on('enable-cursor', enableCursor)
      .on('disable-cursor', disableCursor);
  }
});
