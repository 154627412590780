export const colors = {
	black: '#161616',
	white: '#ffffff',
	primary: '#0323e0',
	secondary: '#ffeeb3',
}

export const easings = {
	default: Expo.EaseOut,
	bounce: Back.easeOut.config(2),
}

export const keyCodes = {
	tab: 9,
	esc: 27,
	end: 35,
	home: 36,
	up: 38,
	down: 40,
}

export const zIndex = {
	modal: 100,
}